/** @jsx jsx */
import { memo } from "react";
import { jsx, Styled } from "theme-ui";

interface Props {
  title?: string | null;
  children: string;
}

const Card: React.FC<Props> = ({ children, title }) => {
  return (
    <div
      sx={{
        backgroundColor: "muted",
        paddingBottom: 5,
        paddingLeft: 4,
        paddingRight: 4,
      }}
    >
      <article sx={{ paddingTop: 5, position: "relative" }}>
        {title && (
          <h1
            sx={{
              color: "secondary",
              fontSize: [4, 4, 4, 3, 4],
              fontWeight: "heading",
              lineHeight: "heading",
              marginBottom: 3,
              marginTop: 0,
            }}
          >
            {title}
          </h1>
        )}
        <div
          sx={{
            backgroundColor: "secondary",
            height: "4px",
            left: 0,
            position: "absolute",
            top: 0,
            width: "3.25rem",
          }}
        />
        <Styled.p sx={{ color: "primary" }}>{children}</Styled.p>
      </article>
    </div>
  );
};

export default memo(Card);
