/** @jsx jsx */

import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx } from "theme-ui";

import { AnimalSolutions_solutionsData } from "../../../../../__generated__/queries";
import { SolutionsSectionQuery } from "../../../../../__generated__/queries";
import Figure from "../../../../components/Figure";
import Figures from "../../../../components/Figures";

const SolutionsFigures: React.FC<{
  figures?: AnimalSolutions_solutionsData["figures"];
  isTerrestrial?: boolean;
}> = ({ isTerrestrial = true, figures }) => {
  const data = useStaticQuery<SolutionsSectionQuery>(graphql`
    query SolutionsSectionQuery {
      leaves: file(relativePath: { eq: "terrestrial-leaves.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
            presentationHeight
            presentationWidth
          }
        }
      }
      algae: file(relativePath: { eq: "aquatic-algae.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
            presentationHeight
            presentationWidth
          }
        }
      }
    }
  `);

  const [bigFigure, smallFigure] = figures || [];
  const bigFigureFluid = bigFigure?.picture?.childImageSharp
    ?.fluid as FluidObject & {
    presentationHeight: number | null;
    presentationWidth: number | null;
  };
  const smallFigureFluid = smallFigure?.picture?.childImageSharp
    ?.fluid as FluidObject & {
    presentationHeight: number | null;
    presentationWidth: number | null;
  };

  const background = (isTerrestrial
    ? data.leaves?.childImageSharp?.fluid
    : data.algae?.childImageSharp?.fluid) as FluidObject & {
    presentationHeight: number | null;
    presentationWidth: number | null;
  };

  return (
    <Figures position={"right"}>
      {bigFigure && (
        <div sx={{ position: "relative" }}>
          {background && (
            <div
              sx={{
                top: ["-4rem", "-6rem", "-6rem", "-10rem"],
                position: "absolute",
                right: ["-5rem", "-12rem", "-12rem", "-14rem"],
                height: [
                  "100%",
                  "100%",
                  "100%",
                  background?.presentationHeight,
                ],
                width: ["100%", "100%", "100%", background?.presentationWidth],
              }}
            >
              <Img fluid={background} />
            </div>
          )}
          <div sx={{ position: "relative" }}>
            <Figure
              caption={bigFigure.caption}
              credits={bigFigure.credits}
              customsx={{
                height: [
                  undefined,
                  undefined,
                  undefined,
                  (bigFigureFluid?.presentationHeight || 0) * 0.75,
                  bigFigureFluid?.presentationHeight,
                ],
                width: [
                  undefined,
                  undefined,
                  undefined,
                  (bigFigureFluid?.presentationWidth || 0) * 0.75,
                  bigFigureFluid?.presentationWidth,
                ],
              }}
            >
              <Img
                alt={bigFigure.alternative_text || ""}
                fluid={bigFigureFluid}
              />
            </Figure>
          </div>
        </div>
      )}
      {smallFigure && (
        <div
          sx={{
            marginTop: [undefined, undefined, undefined, "-6rem", "-12rem"],
          }}
        >
          <Figure
            caption={smallFigure.caption}
            credits={smallFigure.credits}
            customsx={{
              height: [
                undefined,
                undefined,
                undefined,
                (smallFigureFluid?.presentationHeight || 0) * 0.75,
                smallFigureFluid?.presentationHeight,
              ],
              width: [
                undefined,
                undefined,
                undefined,
                (smallFigureFluid?.presentationWidth || 0) * 0.75,
                smallFigureFluid?.presentationWidth,
              ],
            }}
          >
            <Img
              alt={smallFigure.alternative_text || ""}
              fluid={smallFigureFluid}
            />
          </Figure>
        </div>
      )}
    </Figures>
  );
};

export default memo(SolutionsFigures);
