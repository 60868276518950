/** @jsx jsx */

import { graphql } from "gatsby";
import { memo } from "react";
import { jsx } from "theme-ui";

import { AnimalFascination_fascinationData } from "../../../../__generated__/queries";
import CardGrid from "../../../components/CardGrid";
import Heading from "../../../components/Heading";
import Section from "../../../components/Section";

const Fascination: React.FC<{
  hook?: AnimalFascination_fascinationData["hook"];
  title?: AnimalFascination_fascinationData["title"];
  cards?: AnimalFascination_fascinationData["cards"];
}> = ({ hook, title, cards }) => (
  <Section>
    {title && <Heading hook={hook}>{title}</Heading>}
    {cards && (
      <div sx={{ marginBottom: 9 }}>
        <CardGrid cards={cards} />
      </div>
    )}
  </Section>
);

export const query = graphql`
  fragment AnimalFascination on MarkdownRemarkFrontmatter {
    fascinationData: section_5 {
      title
      hook
      cards {
        credits
        content
        caption
        alternative_text
        picture {
          childImageSharp {
            fluid(quality: 70, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default memo(Fascination);
