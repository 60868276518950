/** @jsx jsx */

import { graphql, useStaticQuery } from "gatsby";
import Img, { FixedObject } from "gatsby-image";
import { memo } from "react";
import { jsx } from "theme-ui";

import { AnimalTrivia_triviaData } from "../../../../__generated__/queries";
import { TriviaSectionQuery } from "../../../../__generated__/queries";
import Body from "../../../components/Body";
import Heading from "../../../components/Heading";
import Markdown from "../../../components/Markdown";
import Section from "../../../components/Section";

const Trivia: React.FC<{
  isTerrestrial?: boolean;
  title?: AnimalTrivia_triviaData["title"];
  content?: AnimalTrivia_triviaData["content"];
}> = ({ isTerrestrial = true, title, content }) => {
  const data = useStaticQuery<TriviaSectionQuery>(graphql`
    query TriviaSectionQuery {
      terrestrialEye: file(relativePath: { eq: "terrestrial-eye.png" }) {
        childImageSharp {
          fixed(quality: 70, width: 467, height: 436) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      aquaticEye: file(relativePath: { eq: "aquatic-eye.png" }) {
        childImageSharp {
          fixed(quality: 70, width: 467, height: 436) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Section
      customsx={{ paddingTop: [6, 7, 8] }}
      background={
        <div
          sx={{
            maxWidth: "100%",
            position: "relative",
            height: ["348px", "348px", "348px", "468px"],
            width: ["300px", "436px"],
          }}
        >
          <Img
            fixed={
              (isTerrestrial
                ? data.terrestrialEye?.childImageSharp?.fixed
                : data.aquaticEye?.childImageSharp?.fixed) as FixedObject
            }
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "contain" }}
          />
        </div>
      }
      backgroundPosition={{
        left: "50%",
        top: 0,
        transform: "translate(-50%, -100%)",
        marginTop: "100px",
      }}
      theme="primary"
    >
      {title && (
        <Heading
          customsx={{
            color: "#fff",
            ml: "none",
            textAlign: "center",
            width: "auto",
          }}
        >
          {title}
        </Heading>
      )}
      {content && (
        <Body columns={2}>
          <Markdown>{content}</Markdown>
        </Body>
      )}
    </Section>
  );
};

export const query = graphql`
  fragment AnimalTrivia on MarkdownRemarkFrontmatter {
    triviaData: section_6 {
      title
      content
    }
  }
`;

export default memo(Trivia);
