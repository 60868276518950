/** @jsx jsx */
import { memo } from "react";
import { jsx, SxStyleProp } from "theme-ui";

import Card from "./Card";

export interface Card {
  content?: string | null;
  credits?: string | null;
  alternative_text?: string | null;
  picture?: {
    childImageSharp?: {
      fluid?: {
        base64?: string | null;
        aspectRatio?: number | null;
        src?: string | null;
        srcSet?: string | null;
        sizes?: string | null;
      } | null;
    } | null;
  } | null;
}

interface Props {
  cards: ReadonlyArray<Card | null>;
}

const CardGrid: React.FC<Props> = ({ cards }) => {
  const filteredCards = cards.filter(Boolean) as ReadonlyArray<Card>;

  return (
    <div
      sx={
        {
          display: "grid",
          gridGap: 3,
          gridTemplateColumns: [
            "auto",
            "1fr 1fr",
            "1fr 1fr 1fr",
            "1fr 1fr 1fr",
            "1fr 1fr 1fr 1fr",
          ],
        } as SxStyleProp
      }
    >
      {filteredCards.map((card, index) => (
        <Card key={index} card={card} />
      ))}
    </div>
  );
};

export default memo(CardGrid);
