/** @jsx jsx */

import { graphql } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx } from "theme-ui";

import { AnimalRole_roleData } from "../../../../__generated__/queries";
import Body from "../../../components/Body";
import Figure from "../../../components/Figure";
import Figures from "../../../components/Figures";
import Heading from "../../../components/Heading";
import Markdown from "../../../components/Markdown";
import Section from "../../../components/Section";

const Role: React.FC<{
  hook?: AnimalRole_roleData["hook"];
  title?: AnimalRole_roleData["title"];
  content?: AnimalRole_roleData["content"];
  figures?: AnimalRole_roleData["figures"];
}> = ({ hook, title, content, figures }) => (
  <Section theme="primary">
    <div
      sx={{
        marginTop: (theme): number[] => [0, 0, 0, theme.space[10] * -1],
        marginBottom: [6, 6, 6, 9],
      }}
    >
      <Figures position="center">
        {(figures || []).map((figure, index) =>
          figure ? (
            <Figure
              key={index}
              credits={figure.credits}
              caption={figure.caption}
              customsx={{
                height: [
                  undefined,
                  undefined,
                  undefined,
                  figure.picture?.childImageSharp?.fluid?.presentationHeight,
                ],
                width: [
                  undefined,
                  undefined,
                  undefined,
                  figure.picture?.childImageSharp?.fluid?.presentationWidth,
                ],
              }}
            >
              <Img
                alt={figure.alternative_text || ""}
                fluid={figure.picture?.childImageSharp?.fluid as FluidObject}
              />
            </Figure>
          ) : null
        )}
      </Figures>
    </div>
    {title && (
      <Heading hook={hook} customsx={{ color: "#fff" }}>
        {title}
      </Heading>
    )}
    {content && (
      <div sx={{ marginBottom: [0, 0, 0, 4] }}>
        <Body columns={2}>
          <Markdown>{content}</Markdown>
        </Body>
      </div>
    )}
  </Section>
);

export const query = graphql`
  fragment AnimalRole on MarkdownRemarkFrontmatter {
    roleData: section_2 {
      title
      hook
      content
      figures {
        picture {
          childImageSharp {
            fluid(quality: 70, maxWidth: 540) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }
        credits
        caption
        alternative_text
      }
    }
  }
`;

export default memo(Role);
