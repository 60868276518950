/** @jsx jsx */

import { graphql } from "gatsby";
import { memo } from "react";
import { jsx, Styled } from "theme-ui";

import { AnimalDangers_dangersData } from "../../../../__generated__/queries";
import CardStack from "../../../components/CardStack";
import Heading from "../../../components/Heading";
import Section from "../../../components/Section";

const Dangers: React.FC<{
  hook?: AnimalDangers_dangersData["hook"];
  title?: AnimalDangers_dangersData["title"];
  content?: AnimalDangers_dangersData["content"];
  cards?: AnimalDangers_dangersData["cards"];
}> = ({ hook, title, content, cards }) => (
  <Section>
    {title && <Heading hook={hook}>{title}</Heading>}
    {content && (
      <div sx={{ marginBottom: [5, 5, 5, 6] }}>
        <Styled.p
          sx={{
            color: "secondary",
            fontSize: [2, 2, 2, 3],
          }}
        >
          {content}
        </Styled.p>
      </div>
    )}
    {cards && <CardStack cards={cards} />}
  </Section>
);

export const query = graphql`
  fragment AnimalDangers on MarkdownRemarkFrontmatter {
    dangersData: section_1 {
      hook
      title
      content
      cards {
        title
        content
      }
    }
  }
`;

export default memo(Dangers);
