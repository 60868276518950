/** @jsx jsx */
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx, SxStyleProp } from "theme-ui";

import { Card as CardInterface } from "..";
import Figure from "../../Figure";
import Markdown from "../../Markdown";

const Card: React.FC<{ card: CardInterface }> = ({ card }) => {
  const commonStyle: SxStyleProp = {
    backgroundColor: "muted",
  };

  return (
    <article sx={{ ...commonStyle, paddingBottom: 4 }}>
      {card.picture && (
        <div sx={{ marginBottom: 3, lineHeight: 0 }}>
          <Figure
            credits={card.credits}
            shadow={false}
            customsx={{ width: "100%" }}
          >
            <Img
              alt={card.alternative_text || ""}
              fluid={card.picture?.childImageSharp?.fluid as FluidObject}
            />
          </Figure>
        </div>
      )}
      {card.title && (
        <h1
          sx={{
            color: "secondary",
            fontSize: [3, 3, 3, 3, 4],
            fontWeight: "heading",
            lineHeight: "heading",
            marginBottom: 3,
            marginTop: 0,
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          {card.title}
        </h1>
      )}
      {card.content && (
        <div sx={{ color: "text", paddingLeft: 3, paddingRight: 3 }}>
          <Markdown>{card.content}</Markdown>
        </div>
      )}
    </article>
  );
};

export default memo(Card);
