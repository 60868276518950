/** @jsx jsx */
import { memo } from "react";
import { jsx } from "theme-ui";

const Body: React.FC<{ children: React.ReactNode; columns?: number }> = ({
  children,
  columns = 1,
}) => (
  <div
    sx={{
      columnGap: 4,
      columns: [1, 1, 1, columns],
    }}
  >
    {children}
  </div>
);

export default memo(Body);
