/** @jsx jsx */

import { graphql } from "gatsby";
import { memo } from "react";
import { jsx } from "theme-ui";

import { AnimalSources_sourcesData } from "../../../../__generated__/queries";
import Markdown from "../../../components/Markdown";
import Section from "../../../components/Section";

const Sources: React.FC<{
  content?: AnimalSources_sourcesData["content"];
}> = ({ content }) => (
  <Section theme="muted">
    {content && (
      <div
        sx={{
          color: "text",
          wordBreak: "break-word",
        }}
      >
        <Markdown>{content}</Markdown>
      </div>
    )}
  </Section>
);

export const query = graphql`
  fragment AnimalSources on MarkdownRemarkFrontmatter {
    sourcesData: section_7 {
      content
    }
  }
`;

export default memo(Sources);
