/** @jsx jsx */

import { graphql } from "gatsby";
import { memo } from "react";
import { jsx } from "theme-ui";

import { AnimalSolutions_solutionsData } from "../../../../__generated__/queries";
import CardCarousel from "../../../components/CardCarousel";
import Heading from "../../../components/Heading";
import Section from "../../../components/Section";
import Figures from "./Figures";

const Solutions: React.FC<{
  carousel?: AnimalSolutions_solutionsData["carousel"];
  figures?: AnimalSolutions_solutionsData["figures"];
  hook?: AnimalSolutions_solutionsData["hook"];
  isTerrestrial?: boolean;
  title?: AnimalSolutions_solutionsData["title"];
}> = ({ isTerrestrial = true, hook, title, carousel, figures }) => (
  <Section>
    {title && <Heading hook={hook}>{title}</Heading>}
    <div
      sx={{
        marginBottom: [5, 6, 6, 8, 8],
        width: "calc(100% + (100vw - 100%)/2)",
      }}
    >
      <CardCarousel cards={carousel} />
    </div>
    {(figures || []).length > 0 && (
      <Figures figures={figures} isTerrestrial={isTerrestrial} />
    )}
  </Section>
);

export const query = graphql`
  fragment AnimalSolutions on MarkdownRemarkFrontmatter {
    solutionsData: section_4 {
      carousel {
        caption
        content
        credits
        title
        alternative_text
        picture {
          childImageSharp {
            fluid(quality: 80, maxWidth: 420) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      title
      hook
      figures {
        credits
        caption
        alternative_text
        picture {
          childImageSharp {
            fluid(quality: 70, maxWidth: 870) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default memo(Solutions);
