/** @jsx jsx */
import React, { memo } from "react";
import { jsx } from "theme-ui";

const Figures: React.FC<{
  children: React.ReactNode;
  position: "left" | "center" | "right";
}> = ({ children, position = "left" }) => {
  const [mainFigure, secondaryFigure] = React.Children.toArray(children);

  return (
    <div sx={{ textAlign: position }}>
      <div
        sx={{
          display: ["block", "block", "block", "inline-flex"],
          position: "relative",
          lineHeight: 0,
        }}
      >
        {mainFigure}
        <div
          sx={{
            position: ["static", "static", "static", "absolute"],
            left: 0,
            bottom: 0,
            transform: ["none", "none", "none", "translate(-50%, 50%)"],
            marginTop: 4,
          }}
        >
          {secondaryFigure}
        </div>
      </div>
    </div>
  );
};

export default memo(Figures);
