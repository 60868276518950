/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsx jsx */

import { graphql, Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx, Styled, ThemeProvider } from "theme-ui";

import { AnimalPageQuery } from "../../../__generated__/queries";
import AnimalBadges from "../../components/AnimalBadges";
import BackToTop from "../../components/BackToTop";
import Container from "../../components/Container";
import Figure from "../../components/Figure";
import Footer from "../../components/Footer";
import Heading from "../../components/Heading";
import MainMenu from "../../components/MainMenu";
import Markdown from "../../components/Markdown";
import Section from "../../components/Section";
import SEO from "../../components/SEO";
import themeAquatic from "../../theme-aquatic";
import themeTerrestrial from "../../theme-terrestrial";
import Challenges from "./Challenges";
import Dangers from "./Dangers";
import Fascination from "./Fascination";
import Header, { AnimalType } from "./Header";
import Role from "./Role";
import Solutions from "./Solutions";
import Sources from "./Sources";
import Trivia from "./Trivia";

const AnimalPage: React.FC<{ data: AnimalPageQuery }> = ({ data }) => {
  const animalData = data.markdownRemark?.frontmatter;
  const {
    headerData,
    dangersData,
    roleData,
    challengesData,
    bannerData,
    solutionsData,
    fascinationData,
    triviaData,
    sourcesData,
    seoData,
  } = animalData || {};

  const isTerrestrial = animalData?.type === "terrestrial";

  return (
    <ThemeProvider theme={isTerrestrial ? themeTerrestrial : themeAquatic}>
      <SEO
        description={seoData?.description || animalData?.hook}
        image={seoData?.picture?.childImageSharp?.fluid?.src}
        title={seoData?.title || animalData?.name}
        type="article"
        url={animalData?.url}
      />
      <Styled.root>
        <div sx={{ overflowX: "hidden" }}>
          <Header
            animal={headerData?.animal}
            animalName={animalData?.name}
            background={headerData?.background}
            gradientStart={headerData?.gradientStart}
            gradientStop={headerData?.gradientStop}
            name={headerData?.name}
            theme={(animalData?.type || undefined) as AnimalType}
          >
            <MainMenu theme={(animalData?.type || undefined) as AnimalType} />
          </Header>
          <main>
            <Dangers
              hook={dangersData?.hook}
              title={dangersData?.title}
              content={dangersData?.content}
              cards={dangersData?.cards}
            />
            <Role
              hook={roleData?.hook}
              title={roleData?.title}
              content={roleData?.content}
              figures={roleData?.figures}
            />
            <Challenges
              isTerrestrial={isTerrestrial}
              hook={challengesData?.hook}
              title={challengesData?.title}
              lead={challengesData?.lead}
              content={challengesData?.content}
              figure={challengesData?.figure}
            />
            {bannerData?.picture?.childImageSharp?.fluid && (
              <Figure
                credits={bannerData?.credits}
                shadow={false}
                customsx={{ width: "100%" }}
              >
                <Img
                  alt={bannerData?.alternative_text || ""}
                  fluid={
                    bannerData?.picture?.childImageSharp?.fluid as FluidObject
                  }
                />
              </Figure>
            )}
            <Solutions
              isTerrestrial={isTerrestrial}
              hook={solutionsData?.hook}
              title={solutionsData?.title}
              carousel={solutionsData?.carousel}
              figures={solutionsData?.figures}
            />
            <Fascination
              hook={fascinationData?.hook}
              title={fascinationData?.title}
              cards={fascinationData?.cards}
            />
            <Trivia
              isTerrestrial={isTerrestrial}
              title={triviaData?.title}
              content={triviaData?.content}
            />
            <Section>
              <Heading hook={"Quelles autres espèces sont menacées ?"}>
                Consultez ces articles et approfondissez vos recherches
              </Heading>
              <AnimalBadges activeAnimal={data.markdownRemark?.id} />
            </Section>
            <Sources content={sourcesData?.content} />
            <div sx={{ backgroundColor: "muted" }}>
              <Container>
                <div>
                  <div
                    sx={{
                      color: "text",
                      backgroundColor: "background",
                      padding: [4, 5, 5, 8],
                      position: "relative",
                    }}
                  >
                    <Heading
                      customsx={{
                        fontSize: 5,
                        marginBottom: 3,
                        ml: 0,
                        width: "auto",
                      }}
                    >
                      {data.about?.frontmatter?.page_name}
                    </Heading>
                    <div sx={{ color: "secondary", marginBottom: 3 }}>
                      <Markdown>{data.about?.frontmatter?.extract}</Markdown>
                    </div>
                    <div sx={{ textAlign: "right" }}>
                      <Styled.a
                        as={Link}
                        // @ts-ignore
                        to={
                          data.about?.frontmatter?.url
                            ? `/${data.about?.frontmatter?.url}`
                            : "/"
                        }
                      >
                        En savoir plus ›
                      </Styled.a>
                    </div>
                  </div>
                </div>
              </Container>
            </div>
          </main>
          <Footer isTerrestrial={isTerrestrial} />
          <BackToTop />
        </div>
      </Styled.root>
    </ThemeProvider>
  );
};

export const query = graphql`
  query AnimalPageQuery($fileAbsolutePath: String) {
    markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      id
      frontmatter {
        type
        name
        url
        hook
        ...SEO
        ...AnimalHeader
        ...AnimalDangers
        ...AnimalRole
        ...AnimalChallenges
        bannerData: figure {
          picture {
            childImageSharp {
              fluid(quality: 70, maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alternative_text
          credits
        }
        ...AnimalSolutions
        ...AnimalFascination
        ...AnimalTrivia
        ...AnimalSources
      }
    }
    about: markdownRemark(fileAbsolutePath: { glob: "**/a-propos.md" }) {
      frontmatter {
        url
        page_name
        extract
      }
    }
  }
`;

export default memo(AnimalPage);
