/** @jsx jsx */
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx } from "theme-ui";

import { Card } from "..";
import Figure from "../../Figure";
import Markdown from "../../Markdown";

const CardGrid: React.FC<{ card: Card }> = ({ card }) => {
  if (card.content) {
    return (
      <div
        sx={{
          backgroundColor: "muted",
          color: "text",
          padding: 4,
          position: "relative",
          "::before": {
            backgroundColor: "secondary",
            content: '""',
            height: "2rem",
            left: 0,
            position: "absolute",
            top: 0,
            width: ".25rem",
          },
        }}
      >
        <Markdown>{card.content}</Markdown>
      </div>
    );
  }

  if (card.picture?.childImageSharp?.fluid) {
    return (
      <div sx={{ lineHeight: 0 }}>
        <Figure
          credits={card.credits}
          customsx={{ height: "100%", width: "100%" }}
          shadow={false}
        >
          <Img
            alt={card.alternative_text || ""}
            fluid={card.picture.childImageSharp.fluid as FluidObject}
            style={{ height: "100%" }}
          />
        </Figure>
      </div>
    );
  }

  return null;
};

export default memo(CardGrid);
