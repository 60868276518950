/** @jsx jsx */
import { memo, useRef } from "react";
import { jsx, SxStyleProp } from "theme-ui";

import Card from "./Card";

export interface Card {
  title?: string | null;
  content?: string | null;
  credits?: string | null;
  alternative_text?: string | null;
  picture?: {
    childImageSharp?: {
      fluid?: {
        base64?: string | null;
        aspectRatio?: number | null;
        src?: string | null;
        srcSet?: string | null;
        sizes?: string | null;
      } | null;
    } | null;
  } | null;
}

interface Props {
  cards?: ReadonlyArray<Card | null> | null;
}

const CardCarousel: React.FC<Props> = ({ cards }) => {
  const startClientX = useRef<number>(0);
  const lastHandleOffset = useRef<number>(0);
  const frame = useRef<HTMLDivElement>(null);
  const track = useRef<HTMLDivElement>(null);
  const handle = useRef<HTMLDivElement>(null);

  const filteredCards = (cards || []).filter(Boolean) as ReadonlyArray<Card>;

  const moveHandle = (): void => {
    const scrollWidth =
      (frame.current?.scrollWidth || 0) - (frame.current?.offsetWidth || 0);
    const trackMaxWidth =
      (track.current?.offsetWidth || 0) - (handle.current?.offsetWidth || 0);
    const ratio = trackMaxWidth / scrollWidth;

    if (handle.current) {
      handle.current.style.left = `${
        (frame.current?.scrollLeft || 0) * ratio
      }px`;
    }
  };

  const doMove = (amount: number): void => {
    let nextAmount = amount;
    const scrollWidth =
      (frame.current?.scrollWidth || 0) - (frame.current?.offsetWidth || 0);
    const trackMaxWidth =
      (track.current?.offsetWidth || 0) - (handle.current?.offsetWidth || 0);
    const ratio = trackMaxWidth / scrollWidth;

    if (nextAmount < 0) {
      nextAmount = 0;
    }

    if (nextAmount > trackMaxWidth) {
      nextAmount = trackMaxWidth;
    }

    if (frame.current) {
      frame.current.scrollLeft = nextAmount / ratio;
    }

    if (handle.current) {
      moveHandle();
    }
  };

  const handleMouseMove = (event: MouseEvent): void => {
    const clientX = event.clientX;
    const nextScrollAmount =
      lastHandleOffset.current + clientX - startClientX.current;
    doMove(nextScrollAmount);
  };

  const handleTouchMove = (event: TouchEvent): void => {
    const clientX = event.touches[0].clientX;
    const nextScrollAmount =
      lastHandleOffset.current + clientX - startClientX.current;
    doMove(nextScrollAmount);
  };

  const handleMouseUp = (): void => {
    window.removeEventListener("mousemove", handleMouseMove);
    window.removeEventListener("mouseup", handleMouseUp);
  };

  const handleTouchUp = (): void => {
    window.removeEventListener("touchmove", handleTouchMove);
    window.removeEventListener("touchend", handleMouseUp);
  };

  return (
    <div>
      <div
        ref={track}
        sx={{
          height: 1,
          backgroundColor: "accent",
          position: "relative",
          marginBottom: 5,
        }}
      >
        <div
          ref={handle}
          onTouchStart={(event: React.TouchEvent): void => {
            startClientX.current = event.touches[0].clientX;
            lastHandleOffset.current = handle.current?.offsetLeft || 0;
            window.addEventListener("touchmove", handleTouchMove);
            window.addEventListener("touchend", handleTouchUp);
          }}
          onMouseDown={(event: React.MouseEvent): void => {
            startClientX.current = event.clientX;
            lastHandleOffset.current = handle.current?.offsetLeft || 0;
            window.addEventListener("mousemove", handleMouseMove);
            window.addEventListener("mouseup", handleMouseUp);
          }}
          sx={{
            cursor: "col-resize",
            height: "2rem",
            marginTop: "-1rem",
            position: "absolute",
            width: "8rem",
            left: 0,
            userSelect: "none",
            "::before": {
              backgroundColor: "accent",
              borderRadius: "1rem",
              content: `""`,
              height: "1rem",
              left: "50%",
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
              width: "1rem",
            },
            "::after": {
              backgroundColor: "accent",
              content: `""`,
              height: "5px",
              left: 0,
              marginTop: "-2px",
              position: "absolute",
              top: "50%",
              width: "8rem",
            },
          }}
        />
      </div>
      <div
        ref={frame}
        onScroll={(): void => {
          moveHandle();
        }}
        sx={
          {
            alignItems: "flex-start",
            display: "flex",
            overflow: "scroll",
            msOverflowStyle: "none",
            "::-webkit-scrollbar": {
              display: "none",
            },
          } as SxStyleProp
        }
      >
        {filteredCards.map((card, index) => (
          <div
            key={index}
            sx={{
              maxWidth: ["260px", "320px", "420px"],
              minWidth: ["260px", "320px", "420px"],
              paddingRight: [3, 4],
            }}
          >
            <Card card={card} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(CardCarousel);
