/** @jsx jsx */

import ArrowUpCircle from "mdi-material-ui/ArrowUpCircle";
import { memo, useEffect, useState } from "react";
import { jsx } from "theme-ui";

const BackToTop: React.FC<Record<string, unknown>> = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const listener = (): void => {
    if (window.scrollY > window.innerHeight) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listener);
    return (): void => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return (
    <div
      sx={{
        position: "fixed",
        bottom: 3,
        right: 3,
        opacity: visible ? 1 : 0,
        transition: "opacity 300ms",
      }}
    >
      <a
        sx={{
          color: "rgba(0,0,0,.5)",
          cursor: "pointer",
          display: "block",
          fontSize: 6,
          lineHeight: 1,
        }}
        title="Retour en haut de la page"
        onClick={(): void => {
          window.scrollTo(0, 0);
        }}
      >
        <ArrowUpCircle fontSize={"inherit"} style={{ display: "block" }} />
      </a>
    </div>
  );
};

export default memo(BackToTop);
