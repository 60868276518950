/** @jsx jsx */

import { graphql } from "gatsby";
import { memo } from "react";
import { jsx } from "theme-ui";

import {
  AnimalHeader_headerData,
  AnimalPageQuery_markdownRemark_frontmatter,
} from "../../../../__generated__/queries";
import Header from "../../../components/AnimalHeader";
import HeaderSubaquatic from "../../../components/AnimalHeaderSubaquatic";

export type AnimalType = "terrestrial" | "subaquatic" | "aquatic";

const AnimalHeader: React.FC<{
  animal?: AnimalHeader_headerData["animal"];
  animalName?: AnimalPageQuery_markdownRemark_frontmatter["name"];
  background?: AnimalHeader_headerData["background"];
  children?: React.ReactNode;
  gradientStart?: AnimalHeader_headerData["gradientStart"];
  gradientStop?: AnimalHeader_headerData["gradientStop"];
  name?: AnimalHeader_headerData["name"];
  theme?: AnimalType;
}> = ({
  animal,
  animalName,
  background,
  gradientStart,
  gradientStop,
  name,
  theme = "terrestrial",
  children,
}) => {
  switch (theme) {
    case "terrestrial":
    case "aquatic":
    default:
      return (
        <div sx={{ height: "100vh" }}>
          <Header
            animal={animal?.childImageSharp?.fluid}
            animalName={animalName}
            background={background?.childImageSharp?.fluid}
            gradientStart={gradientStart}
            gradientStop={gradientStop}
            name={name?.childImageSharp?.fluid}
          >
            {children}
          </Header>
        </div>
      );
    case "subaquatic":
      return (
        <div sx={{ height: "100vh" }}>
          <HeaderSubaquatic
            animal={animal?.childImageSharp?.fluid}
            animalName={animalName}
            background={background?.childImageSharp?.fluid}
            name={name?.childImageSharp?.fluid}
          >
            {children}
          </HeaderSubaquatic>
        </div>
      );
  }
};

export const query = graphql`
  fragment AnimalHeader on MarkdownRemarkFrontmatter {
    headerData: header {
      gradientStop: gradient_stop
      gradientStart: gradient_start
      name {
        childImageSharp {
          fluid(quality: 10, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      animal {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default memo(AnimalHeader);
