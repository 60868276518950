/** @jsx jsx */

import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { memo } from "react";
import { jsx } from "theme-ui";

import { AnimalChallenges_challengesData } from "../../../../__generated__/queries";
import { ChallengesSectionQuery } from "../../../../__generated__/queries";
import Body from "../../../components/Body";
import Figure from "../../../components/Figure";
import Heading from "../../../components/Heading";
import Lead from "../../../components/Lead";
import Markdown from "../../../components/Markdown";
import Section from "../../../components/Section";

const Challenges: React.FC<{
  content?: AnimalChallenges_challengesData["content"];
  figure?: AnimalChallenges_challengesData["figure"];
  hook?: AnimalChallenges_challengesData["hook"];
  isTerrestrial?: boolean;
  lead?: AnimalChallenges_challengesData["lead"];
  title?: AnimalChallenges_challengesData["title"];
}> = ({ isTerrestrial = true, hook, title, lead, content, figure }) => {
  const data = useStaticQuery<ChallengesSectionQuery>(graphql`
    query ChallengesSectionQuery {
      dirt: file(relativePath: { eq: "terrestrial-dirt.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
            presentationHeight
            presentationWidth
          }
        }
      }
      starfish: file(relativePath: { eq: "aquatic-starfish.png" }) {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1920) {
            ...GatsbyImageSharpFluid
            presentationHeight
            presentationWidth
          }
        }
      }
    }
  `);

  return (
    <Section>
      <div sx={{ textAlign: "center" }}>
        <div
          sx={{
            display: ["block", "block", "block", "inline-block"],
            lineHeight: 0,
            marginBottom: [6, 6, 6, 8],
            marginTop: (theme): number[] => [0, 0, 0, theme.space[10] * -1],
            position: "relative",
          }}
        >
          {isTerrestrial ? (
            <div
              sx={{
                position: "absolute",
                bottom: "-6rem",
                right: "-10rem",
                height:
                  data.dirt?.childImageSharp?.fluid?.presentationHeight || 0,
                width:
                  data.dirt?.childImageSharp?.fluid?.presentationWidth || 0,
              }}
            >
              <Img fluid={data.dirt?.childImageSharp?.fluid as FluidObject} />
            </div>
          ) : (
            <div
              sx={{
                position: "absolute",
                bottom: "-9rem",
                right: "-13rem",
                height:
                  data.starfish?.childImageSharp?.fluid?.presentationHeight ||
                  0,
                width:
                  data.starfish?.childImageSharp?.fluid?.presentationWidth || 0,
              }}
            >
              <Img
                fluid={data.starfish?.childImageSharp?.fluid as FluidObject}
              />
            </div>
          )}
          {figure && (
            <Figure
              caption={figure.caption}
              credits={figure.credits}
              customsx={{
                height: [
                  undefined,
                  undefined,
                  undefined,
                  figure.picture?.childImageSharp?.fluid?.presentationHeight,
                ],
                width: [
                  undefined,
                  undefined,
                  undefined,
                  figure.picture?.childImageSharp?.fluid?.presentationWidth,
                ],
              }}
            >
              <Img
                alt={figure.alternative_text || ""}
                fluid={figure.picture?.childImageSharp?.fluid as FluidObject}
              />
            </Figure>
          )}
        </div>
      </div>
      {title && <Heading hook={hook}>{title}</Heading>}
      {lead && <Lead>{lead}</Lead>}
      {content && (
        <div sx={{ color: "text" }}>
          <Body columns={2}>
            <Markdown>{content}</Markdown>
          </Body>
        </div>
      )}
    </Section>
  );
};

export const query = graphql`
  fragment AnimalChallenges on MarkdownRemarkFrontmatter {
    challengesData: section_3 {
      title
      lead
      hook
      content
      figure {
        picture {
          childImageSharp {
            fluid(quality: 70, maxWidth: 540) {
              ...GatsbyImageSharpFluid
              presentationHeight
              presentationWidth
            }
          }
        }
        credits
        caption
        alternative_text
      }
    }
  }
`;

export default memo(Challenges);
