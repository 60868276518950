/** @jsx jsx */
import { memo } from "react";
import { jsx } from "theme-ui";

import Card from "./Card";

interface Card {
  title?: string | null;
  content?: string | null;
}

interface Props {
  cards: ReadonlyArray<Card | null>;
}

const CardStack: React.FC<Props> = ({ cards = [] }) => {
  const filteredCards = cards.filter(Boolean) as ReadonlyArray<Card>;

  return (
    <article>
      {filteredCards.map(({ title, content }, index) =>
        content ? (
          <div
            key={index}
            sx={{
              marginBottom: 4,
              "&:last-of-type": {
                marginBottom: 0,
              },
            }}
          >
            <Card title={title}>{content}</Card>
          </div>
        ) : null
      )}
    </article>
  );
};

export default memo(CardStack);
